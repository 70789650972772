import creatureList from "@/constants/creatureList"
import {CREATURE_TYPE_UNDEAD} from "@/constants/creatureTypeIdList"
import { crCollection } from '@/constants/crList'

import calcDiceAverage from "@/utils/calcDiceAverage"
import generateHpDiceFormula from '@/utils/generateHpDiceFormula'

import WAND_HP_LIMIT from "./WAND_HP_LIMIT"
import CR_MIN_DEFAULT from "./CR_MIN_DEFAULT"

export default creatureList.filter(
  ({creatureTypeIdList, cr, hp, sizeType, params}) => {
    const hpDiceFormula = generateHpDiceFormula({ hp, sizeType, params })

    return (
      cr
      && creatureTypeIdList.includes(CREATURE_TYPE_UNDEAD)
      && calcDiceAverage(hpDiceFormula) <= WAND_HP_LIMIT
      && crCollection[cr].exp >= crCollection[CR_MIN_DEFAULT].exp
    )
  }
)
